<template>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol md="5">
                      <h4>Monitoring</h4>
                </CCol>
                <CCol md="5">
                    <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                    <!-- <span style="color:green; font-style: italic;">* Search by []. Press ENTER after input</span> -->
                </CCol>
                <CCol md="2">
                    <CButton v-on:click="actionSearch()" color="info">Search</CButton>
                </CCol>
            </CRow>
          </CCardHeader><br/>
          <CCardBody>
            <CRow>
              <CCol col="12" class="text-left">
                 <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
            </CRow>

            <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
            </div>


            <!-- <div>
              <b-button v-b-modal.modal-1>Launch demo modal</b-button>
              <b-modal id="modal-1" title="BootstrapVue">
                <p class="my-4">Hello from modal!</p>
              </b-modal>
            </div> -->


                    <CRow>
                        <CCol md="6">
                            <CSelect
                                label="Transaction Type"
                                @change="onChangeTrType"
                                :options="optTrType"
                                horizontal>
                            </CSelect>
                        </CCol>
                    </CRow><br/>

            <div class="table-responsive table">
              <label>* Click the row for show the rules applied</label>
              <b-table sticky-header striped hover
                  @row-clicked="toggleDetails"
                  :items="items"
                  :fields="fields"
                  :filter="filter"
                  sort-by="createDate"
                  :sort-desc="true">


                <template v-slot:cell(createDate)="{ item }">
                    {{ item.createDate | dateWithTime }}
                </template>

                <template slot="row-details" slot-scope="row">
                  <b-card>
                    <h5>Rules Applied for Trs ID: {{ row.item.clientTransactionId }}</h5>
                    <ul id="rulelist">
                    <li v-for="(item, key) in row.item.rulesApplied" :key="key">
                      {{ key }} - {{ item }}
                    </li>
                  </ul>
                  </b-card>
                </template>

              </b-table>
            </div>

            <div>
              <b-row>
                <b-col sm="3">
                   <b-pagination size="md"
                    :total-rows="count"
                    :per-page="pageSize"
                    v-model="page"
                    @change="handlePageChange"
                    />
                </b-col>
                <b-col sm="9">
                  <b-form-select v-model="pageSize"  style="width:100px;" @change="handlePageSizeChange($event)">
                    <option v-for="size in pageSizes" :key="size" :value="size">
                        {{ size }}
                    </option>
                </b-form-select>
                </b-col>
              </b-row>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </template>

  <script>
  import FraudService from '@/api/FraudService.js';

   export default {
      name: "fraudclienttransact",
      data() {
        return {
          spinner:false,
          searchTitle: "",
          trType: null,
          optTrType: [],
          items:[],
          fields: [
              { key: 'createDate', label : 'Created Date', sortable: true },
              { key: 'transactionTypeCode', label : 'Transaction Type Code', sortable: true },
              { key: 'clientTransactionId', label : 'Client Transaction ID', sortable: true },
              { key: 'senderId', label : 'Sender ID', sortable: true },
              { key: 'receiverId', label : 'Receiver ID', sortable: true },
              { key: 'fromCurrency', label : 'From Currency', sortable: true },
              { key: 'fromAmount', label : 'From Amount', sortable: true },
              { key: 'toCurrency', label : 'To Currency', sortable: true },
              { key: 'toAmount', label : 'To Amount', sortable: true },
              { key: 'status', label : 'Status', sortable: true },
          ],
          currentItem:null,
          currentIndex:-1,
          page: 1,
          count:0,
          size:0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          filter: null,
          seen:false,
          msg :'',
          color:''
        };
      },
      filters: {
          upper(value){
            if(!value) return ''
            value = value.toString()
            return value.toUpperCase()
          },
          lower(value){
             if(!value) return ''
             value = value.toString()
             return value.toLowerCase()
          },

          dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,10)
          },

          IdSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,5) + '...'
          },
          dateWithTime(value){
             if(!value) return ''
             var temp = value.toString().split(".");
             value = temp[0].replace("T"," ");
             return value;
          },

      },
      created () {
        this.getAllTransactionTypes();
      },
      methods: {

        toggleDetails(item) {
          if (item["_showDetails"]) item["_showDetails"] = false;
          else this.$set(item, "_showDetails", true);
        },

        actionSearch(){
          this.retrieveData();
        },

        filterChange(){
           this.retrieveData();
        },

        handlePageChange(value) {
          this.page = value;
          this.retrieveData();
        },

        handlePageSizeChange(event) {
            this.pageSize = event;
            this.page = 1;
            this.retrieveData();
        },

        getRequestParams(page, pageSize) {
            let params = {};

            if (this.filter) {
                params['searchString'] = this.filter;
            }

            if (page) {
                params["pageNumber"] = page - 1;
            }

            if (pageSize) {
                params["pageSize"] = pageSize;
            }

            params["transactionTypeCode"] = this.trType;

            return params;
        },

        getAllTransactionTypes: function () {
            FraudService.getAllTransactionTypes().then(resp => {
                var afTrsType = resp;  var afTrsTypeList = [];
                afTrsTypeList.push({ value: null, label: '--- Select ---' });
                for (var key in afTrsType) {
                    var row  = afTrsType[key];
                    afTrsTypeList.push({ value: row.code, label: row.name });
                }
                this.optTrType = afTrsTypeList;
            }, error => {
                this.spinner = false;
            });
        },

        onChangeTrType(a) {
            this.trType = a.target.value;
            if(this.trType !== null && this.trType !== ''){
               this.retrieveData();
            } else {
               this.trType = null;
               this.retrieveData();
            }
        },

        retrieveData(){
            this.spinner = true;
            const params = this.getRequestParams(
                this.page,
                this.pageSize
            );
            FraudService.getClientTransactions(params).then(resp => {
                if(resp.payload.totalElements > 0){
                  this.items = resp.payload.content;
                } else {
                  this.items =[];
                }
                this.spinner = false;
                this.count = resp.payload.totalElements;
            }, error => {
                this.spinner = false;
            });
        },
      },
      mounted() {
        this.retrieveData();
      },
   };

  </script>
  <style>
      .table{ overflow-y:scroll; }
      .modal-backdrop {
        display: none;
      }
  </style>
